<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
      إضافة ضريبة
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="3" class="mb-3">
            <input-form placeholder="أدخل الاسم" label="الإسم" v-model="tax.title"></input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form placeholder="ادخل الرقم البريدى" label="الرقم البريدى" v-model="tax.post_code"></input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form placeholder="النسبة" label="النسبة" v-model="tax.percentage"></input-form>
          </b-col>
            <b-col md="3" class="mb-3">
              <main-select
                  :reduce="city => city.id"
                  :options="allCities"
                  :validate="'required'"
                  v-model="tax.city"
                  labelTitle="اختر المدينة"
                  placeholder="اختر المدينة"
                  label="name"
                  dir="rtl"
              />
            </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="3">
                <b-button variant="primary" type="submit" v-if="!loadingSubmit">اضافة ضريبة</b-button>
                <b-button v-else variant="primary" class="text-white" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import merchants from '@/modules/marketPlace/services/marketPlace'
import citiesAndCountriesMixin from '@/mixins/countriesAndCities'
export default {
  components: { },
  mixins: [citiesAndCountriesMixin],
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingSubmit: false,
      tax: {
        title: '',
        post_code: '',
        percentage: '',
        city: ''
      }
    }
  },
  methods: {
    onSubmit () {
      this.loadingSubmit = true
      merchants.addTax(this.tax).then(res => {
        console.log(res.data)
        this.$router.push({ name: 'tax' })
      }).finally(() => {
        this.loadingSubmit = false
      })
    }
  }
}
</script>
<style>
.vs__dropdown-toggle {
  background: #fff !important;
  border-radius: 10px !important;
}
</style>
